import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import Layout from "@/views/Layout.vue";
import Home from "@/views/Home.vue";
import Replace from "@/views/Replace.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/replace",
        name: "Replace",
        component: Replace,
      },
    ],
  },
];
//
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
