import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosPromise,
  AxiosError,
} from "axios";

import QS from 'qs'

class Request {
  axiosInstance: AxiosInstance;

  constructor() {
    // 创建axios实例
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
      timeout: 60000,
    });
    this.initInterceptors();
  }
  getInterceptors() {
    return this.axiosInstance;
  }
  getAxios() {
    return axios;
  }
  // 初始化拦截器
  initInterceptors() {
    // 设置post请求头

    // 请求拦截器
    this.axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        config.data = QS.stringify(config.data,{ arrayFormat: 'brackets' })
        return config;
      },
      (error: AxiosError) => {
        console.log(error);
      }
    );

    // 响应拦截器
    this.axiosInstance.interceptors.response.use(
      // 请求成功
      (response: AxiosResponse) => {
        return Promise.resolve(response);
      },
      // 请求失败
      (error: AxiosError) => {
        console.log(`error`, error);
        return Promise.reject(error);
      }
    );
  }
}

export const request = new Request();

// 请求配置
export class HttpServer {
  axios: AxiosInstance;
  // 获取axios实例
  constructor() {
    this.axios = new Request().getInterceptors();
  }
  // 简单封装一下方法
  request(config: AxiosRequestConfig): AxiosPromise {
      return new Promise((resolve, reject) => {
      this.axios(config)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  }
}

export default new HttpServer();
