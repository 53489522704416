
import { Options, Vue } from "vue-class-component";
import { fetch_types, fetch_goods } from "@/api/index";
import SearchTree from "@/components/searchTree.vue";
import { number } from "@intlify/core-base";
interface testDataProp {
  bool?: boolean;
  diyname: string;
  id: number;
  name: string;
}
interface WordProp {
  name: string;
  bool: boolean;
  testData: testDataProp[];
}

interface TypesProp {
  id: number;
  list?: TypesProp[];
  list3?: TypesProp[];
  name: string;
  bool?: boolean;
  switch?: number;
  pid: number;
}

@Options({
  components: {
    SearchTree,
  },
  computed: {
    randomBool() {
      if (this.word.find((item: WordProp) => item.bool)) {
        return false;
      }
      return true;
    },
  },
})
export default class Home extends Vue {
  pageSize = 50;
  total = 50;
  currentPage = 1;
  typeMore = false;
  menuIndex = 1;

  testData = [
    {
      name: "RK33283328",
      bool: false,
    },
    {
      name: "RK33283328",
      bool: false,
    },
    {
      name: "RK33283328",
      bool: false,
    },
    {
      name: "RK33283328",
      bool: false,
    },
    {
      name: "RK33283328",
      bool: false,
    },
    {
      name: "RK33283328",
      bool: false,
    },
    {
      name: "RK33283328",
      bool: false,
    },
  ];

  word: WordProp[] = [];

  tableData = [];

  randomWord: testDataProp[] = [];

  types: TypesProp[] = [];

  handleTypeMore() {
    this.typeMore = !this.typeMore;
  }

  async created() {
    let res = await fetch_types();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let allList3: any[] = [];

    this.types = res.data.data.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: { list3: any[][]; list: { list: any }[] }) => {
        item.list3 = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        item.list.map((citem: { list: any }) => {
          if (citem.list.length) {
            item.list3.push(...citem.list);
            allList3.push(...citem.list);
          }
        });
        return item;
      }
    );

    let word: WordProp[] = [];

    let randomAllWord: testDataProp[] = [];

    for (const key in res.data.msg) {
      if (Object.prototype.hasOwnProperty.call(res.data.msg, key)) {
        const element = res.data.msg[key];
        let testData: testDataProp[] = [];

        for (let i = 0; i < allList3.length; i++) {
          const allItem = allList3[i];
          if (allItem?.diyname === element) {
            testData.push(allItem);
            delete allList3[i];
          }
        }
        randomAllWord.push(...testData);
        word.push({
          name: element,
          bool: false,
          testData,
        });
      }
    }

    this.word = word;
    this.randomWord = this.getRandomArrayElements(randomAllWord, 20);
    this.get_goods();
  }

  async get_goods() {
    let cid: number[] = [];

    for (let i = 0; i < this.types.length; i++) {
      const element = this.types[i].list3;
      if (element) {
        cid.push(
          ...element.reduce((pre, cur) => {
            if (cur.bool) {
              pre.push(cur.id);
            }
            return pre || [];
          }, [] as number[])
        );
      }
    }

    let res = await fetch_goods({
      cid: cid.join(","),
      page: this.currentPage,
      pagesize: this.pageSize,
    });

    this.tableData = res.data.list;
    this.total = res.data.count;
  }

  getRandomArrayElements(arr: testDataProp[], count: number) {
    var shuffled = arr.slice(0),
      i = arr.length,
      min = i - count,
      temp,
      index;
    while (i-- > min) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(min);
  }

  chooseWord(index: number) {
    try {
      for (const item of this.word) {
        item.bool = false;
      }

      console.log(` this.word[index]`, this.word[index].bool);
      this.word[index].bool = !this.word[index].bool;
    } catch (err) {
      console.log(`err`, err);
    }
  }

  chooseNumber(index: number, cindex: number) {
    let item = this.word[index].testData?.[cindex];

    if (item) {
      item.bool = !item.bool;
      this.get_goods();
    }
  }

  chooseRandNumber(index: number) {
    let item = this.randomWord[index];

    if (item) {
      item.bool = !item.bool;
      this.get_goods();
    }
  }

  selectCid(index: number, cindex: number) {
    let item = this.types[index].list3?.[cindex];

    if (item) {
      item.bool = !item.bool;
      this.get_goods();
    }
  }

  searchSelectCid(index: number, data: TypesProp) {
    if (this.types[index].list3) {
      let item = this.types[index].list3?.find((i) => i.id === data.id);
      if (item) {
        item.bool = !item.bool;
        this.get_goods();
      }
    }
  }

  handleBuy(index: number, row: { url: string }) {
    if (this.$i18n.locale === "zh") {
      window.open(row.url);
    } else {
      window.open(row["e_url"]);
    }
  }

  /* 排序 */
  sortMethod(a: any, b: any, id: number) {
    //正序排列 a.sort(function(a,b){return b.localeCompare(a)}); //反序排列
    return (a.ids[id] || "").localeCompare(b.ids[id] || "");
  }

  handleSizeChange(val: number) {
    this.pageSize = val;
    this.get_goods();
  }

  handleCurrentChange(val: number) {
    this.currentPage = val;
    this.get_goods();
  }
}
