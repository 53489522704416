
import { Vue } from "vue-class-component";
import geoip2 from "@/utils/geoip2";
export default class Layout extends Vue {
  menuIndex = 1;
  created() {
    var test = (() => {
      var onSuccess = (geoipResponse) => {
        /* There's no guarantee that a successful response object
         * has any particular property, so we need to code defensively. */
        if (!geoipResponse.country.iso_code) {
          return;
        }
        /* ISO country codes are in upper case. */
        var code = geoipResponse.country.iso_code.toLowerCase();
        console.log("code", code);
        if (code === "cn") {
          this.$i18n.locale = "zh";
        } else {
          this.$i18n.locale = "en";
        }
      };
      var onError = function (error) {
        console.log("error", error);
      };
      return function () {
        // eslint-disable-next-line no-undef
        geoip2().country(onSuccess, onError);
      };
    })();
    if (!localStorage.getItem("locale")) {
      test();
    }

    this.$i18n.locale = localStorage.getItem("locale") || "zh";
  }
  langChange(e: string) {
    localStorage.setItem("locale", e);
    location.reload();
    this.$i18n.locale = e;
  }
}
