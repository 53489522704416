import http from "@/utils/request";

export interface Pagination {
  page: number;
  pagesize: number;
}

export interface GoodsProps extends Pagination {
  cid?: string;
  model?: string;
  name?: string;
}

export function fetch_goods(data: GoodsProps) {
  return http.request({
    url: "/index/getGoods",
    method: "post",
    data,
  });
}
export function fetch_types() {
  return http.request({
    url: "/index/getTypes",
    method: "post",
  });
}
export function fetch_getreplace() {
  return http.request({
    url: "/index/getreplace",
    method: "get",
  });
}
