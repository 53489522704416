import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "replace" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "ctitle" }
const _hoisted_4 = { class: "banner" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "pagination" }
const _hoisted_8 = { class: "total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("replace")), 1),
      _createElementVNode("section", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.replaceList, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass({ bannerItem: true, active: _ctx.replaceCur == index }),
            key: index,
            onClick: ($event: any) => (_ctx.changeReplace(index))
          }, _toDisplayString(item.name), 11, _hoisted_5))
        }), 128))
      ]),
      _createVNode(_component_el_table, {
        class: "goodTable",
        data: _ctx.tableData,
        border: "",
        stripe: "",
        height: "calc(100vh - 200px)",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: _ctx.$t('pName'),
            "min-width": "150"
          }, null, 8, ["label"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (item, index) => {
            return (_openBlock(), _createBlock(_component_el_table_column, {
              "min-width": "180",
              key: index,
              sortable: "",
              sortMethod: 
            (a, b) => {
              return _ctx.sortMethod(a, b, item.id);
            }
          ,
              label: _ctx.$i18n.locale === 'zh' ? item.name : item.nickname
            }, {
              default: _withCtx(({ row }) => [
                (row?.ids)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      innerHTML: row?.ids[item.id] || ''
                    }, null, 8, _hoisted_6))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["sortMethod", "label"]))
          }), 128)),
          _createVNode(_component_el_table_column, {
            label: "操作",
            width: "120",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                size: "mini",
                type: "primary",
                onClick: ($event: any) => (_ctx.handleBuy(scope?.$index, scope?.row))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("buy")), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, "共" + _toDisplayString(_ctx.total) + "件商品", 1),
        _createVNode(_component_el_pagination, {
          currentPage: _ctx.currentPage,
          "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
          "page-size": _ctx.pageSize,
          layout: "sizes, prev, pager, next, jumper",
          total: _ctx.total,
          onSizeChange: _ctx.handleSizeChange,
          onCurrentChange: _ctx.handleCurrentChange
        }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
      ])
    ])
  ]))
}