import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "SearchTree" }
const _hoisted_2 = { class: "SearchTree-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_input, {
      class: "SearchTree-ipt",
      clearable: "",
      modelValue: _ctx.keyword,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keyword) = $event)),
      onInput: _ctx.changeKeyw
    }, {
      prefix: _withCtx(() => [
        _createVNode(_component_el_icon, { style: {"vertical-align":"middle"} }, {
          default: _withCtx(() => [
            _createVNode(_component_Search)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onInput"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tree, {
        ref: "treeRef",
        class: "filter-tree",
        "show-checkbox": "",
        "check-on-click-node": "",
        "highlight-current": "",
        "node-key": "id",
        data: _ctx.list,
        props: _ctx.defaultProps,
        "default-expand-all": "",
        "filter-node-method": _ctx.filterNode,
        onCheckChange: _ctx.checkChange
      }, null, 8, ["data", "props", "filter-node-method", "onCheckChange"])
    ])
  ]))
}