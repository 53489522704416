
import { Options, Vue } from "vue-class-component";
import { Search } from "@element-plus/icons-vue";
import TreeType from "element-plus/es/components/tree/index";

interface Tree {
  id: number;
  name: string;
  children?: Tree[];
}

@Options({
  components: {
    Search,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
})
export default class SearchTree extends Vue {
  keyword = "";
  defaultProps = {
    children: "children",
    label: "name",
  };

  $refs!: {
    treeRef: typeof TreeType;
  };

  changeKeyw(val: string) {
    this.$refs.treeRef.filter(val);
  }

  checkChange(data: Tree, check: boolean) {
    this.$emit("select", { ...data, bool: check });
  }

  filterNode(value: string, data: Tree) {
    if (!value) return true;
    return data.name.toUpperCase().includes(value.toUpperCase());
  }
}
