
import { Vue } from "vue-class-component";
import { fetch_goods, fetch_types, fetch_getreplace } from "@/api/index";

interface TypesProp {
  id: number;
  list?: TypesProp[];
  list3?: TypesProp[];
  name: string;
  nickname: string;
  bool?: boolean;
  pid: number;
}

interface ReplaceProp {
  nickname: string;
  name: string;
  cid: number;
}

export default class Layout extends Vue {
  menuIndex = 1;
  pageSize = 50;
  total = 50;
  currentPage = 1;
  tableData = [];
  replaceList: ReplaceProp[] = [];
  replaceCur = 0;
  types: TypesProp[] = [];

  async created() {
    let resultReplace = await fetch_getreplace();
    this.replaceList = resultReplace.data.data;

    let res = await fetch_types();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let allList3: any[] = [];

    this.types = res.data.data.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: { list3: any[][]; list: { list: any }[] }) => {
        item.list3 = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        item.list.map((citem: { list: any }) => {
          if (citem.list.length) {
            item.list3.push(...citem.list);
            allList3.push(...citem.list);
          }
        });
        return item;
      }
    );

    this.get_goods();
  }

  async get_goods() {
    let cid: number[] = [];

    for (let i = 0; i < this.types.length; i++) {
      const element = this.types[i].list3;
      if (element) {
        cid.push(
          ...element.reduce((pre, cur) => {
            if (cur.bool) {
              pre.push(cur.id);
            }
            return pre;
          }, [] as number[])
        );
      }
    }
    console.log("cid", cid);
    if (this.replaceCur > -1) {
      cid.push(this.replaceList[this.replaceCur].cid);
    }

    let res = await fetch_goods({
      cid: cid.join(","),
      page: this.currentPage,
      pagesize: this.pageSize,
    });

    this.tableData = res.data.list;
    this.total = res.data.count;
  }
  changeReplace(index: number) {
    if (this.replaceCur === index) {
      this.replaceCur = -1;
    } else {
      this.replaceCur = index;
    }
    this.get_goods();
  }

  handleBuy(index: number, row: { url: string }) {
    if (this.$i18n.locale === "zh") {
      window.open(row.url);
    } else {
      window.open(row['e_url']);
    }
  }

  /* 排序 */
  sortMethod(a: any, b: any, id: number) {
    //正序排列 a.sort(function(a,b){return b.localeCompare(a)}); //反序排列
    return (a.ids[id] || "").localeCompare(b.ids[id] || "");
  }

  handleSizeChange(val: number) {
    this.pageSize = val;
    this.get_goods();
  }

  handleCurrentChange(val: number) {
    this.currentPage = val;
    this.get_goods();
  }
}
