export default {
  home: "首页",
  buy: "立刻购买",
  filter: "筛选器",
  product: "产品列表",
  alternatives: "树莓派替代",
  board: "芯板坊",
  pName: "产品名称",
  replace: "替代选型",
};
