export default {
  home: "Home",
  buy: "Buy now",
  filter: "Filter",
  product: "Product List",
  alternatives: "Raspberry Pi alternatives",
  board: "ChipBoard",
  pName: "Product Name",
  replace: "Alternative selection",
};
