import App from "./App.vue";
import { createApp } from "vue";
import router from "./router";
import store from "./store";
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import en from "element-plus/es/locale/lang/en";
import { createI18n } from "vue-i18n";
import zh from "@/lang/zh";
import len from "@/lang/en";

const i18n = createI18n({
  locale: "zh",
  messages: {
    zh,
    en: len,
  },
});

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: localStorage.getItem("locale") === "en" ? en : zhCn,
  })
  .mount("#app");
